import { Box, Divider, List as MUIList, Skeleton } from '@mui/material';
import { Occasion, Player, PlayerData } from '@oskarjiang/matchi-types';
import { ListItem } from './ListItem';
import { baseUrl } from '../../constants/endpoints';
import { useQuery } from '@tanstack/react-query';
import { useFocusedOccasion } from '../../contexts/FocusedOccasionContext';

export const List = () => {
	const { focusedOccasion } = useFocusedOccasion();
	const { data, isLoading } = useQuery<Occasion>({
		queryKey: ['participants', focusedOccasion?.id],
		queryFn: () => fetch(`${baseUrl}/occasion/${focusedOccasion?.id}`).then((res) => res.json()),
	});

	const { data: playerData, isLoading: isLoadingPlayerData } = useQuery<PlayerData[]>({
		queryKey: ['player-data'],
		queryFn: () => fetch(`${baseUrl}/playerData`).then((res) => res.json()),
	});

	return (
		<MUIList component="nav">
			{!isLoading ? (
				data?.occasionParticipants
					.sort((a: Player, b: Player) => {
						const levelA = playerData?.find((player) => player.player.userId === a.userId)?.averageLevel;
						const levelB = playerData?.find((player) => player.player.userId === b.userId)?.averageLevel;
						if (levelA === undefined || levelA === null) {
							return 1;
						}
						if (levelB === undefined || levelB === null) {
							return -1;
						}
						return levelB - levelA;
					})
					.map((participant: Player, index: number) => (
						<>
							<ListItem
								participant={participant}
								key={participant.userId}
								averageLevel={playerData?.find((player) => player.player.userId === participant.userId)?.averageLevel ?? undefined}
								isLoading={isLoadingPlayerData}
							/>
							{(index + 1) % 4 === 0 && index + 1 !== data.occasionParticipants.length ? <Divider component="li" /> : <></>}
						</>
					))
			) : (
				<>
					<Box sx={{ m: 0.5 }}>
						<Skeleton variant="rounded" width="100%" height={72} />
					</Box>
					<Box sx={{ m: 0.5 }}>
						<Skeleton variant="rounded" width="100%" height={72} />
					</Box>
					<Box sx={{ m: 0.5 }}>
						<Skeleton variant="rounded" width="100%" height={72} />
					</Box>
				</>
			)}
		</MUIList>
	);
};
